<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลการชำระเงิน</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <div class="mb-4">
                <label class="form-label">รูปแบบการจ่ายเงิน</label>
                <select class="form-control" v-model="item.type" @change="changeTypePayment()">
                  <option v-for="data in type_payments" :key="data.id" :value="data.id" >{{ data.text }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <label class="form-label">หมายเลขอ้างอิง</label>
                <base-input name="ref" placeholder="หมายเลขอ้างอิง" v-model="item.ref"></base-input>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <label class="form-label">จำนวนเงินทั้งหมด</label>
                <base-input name="total" v-decimal-format placeholder="จำนวนเงินทั้งหมด" :rules="{ required: true }" v-model="item.amount"></base-input>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <label class="form-label">สถานะการชำระเงิน</label>
                <select class="form-control" v-model="item.status">
                  <option v-for="data in status_payments" :key="`list_box_status_payment_${data.id}`" :value="data.id"> {{ data.text }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <label class="form-label optional">วันที่/เวลาโอนเงินตามสลิป</label>
                <date-picker
                  :format="'DD/MM/YYYY HH:mm:ss'"
                  v-model="item.payment"
                  class="w-100"
                  input-class="form-control"
                  placeholder="วันที่/เวลาโอนเงินตามสลิป"
                  :append-to-body="true"
                  type="datetime"
                ></date-picker>
              </div>
            </div>
            <div class="col-12"><hr class="my-4" /></div>
            <div class="col-12">
              <h6 class="heading-small text-muted mb-4">ข้อมูลการสั่งซื้อ</h6>
            </div>
            <div class="col-md-3">
              <div class="mb-4">
                <label class="form-label">ลูกค้า</label>
                <Select2Customer 
                  v-model="item.customer_id"
                  @change="getCustomerById"
                  ref="Select2Customer"
                ></Select2Customer>
              </div>
            </div>
            <div class="col-12">
              <b-button variant="primary" class="style_btn" style="width: 125px" @click="onAddOrderHandler()" v-if="flagEdit">
                <i class="fa fa-plus"></i> เพิ่มการสั่งซื้อ
              </b-button>
              <div class="table-responsive padding_top_20">
                <table class="table align-middle table-bordered">
                  <thead>
                    <tr class="text-center">
                      <th>รหัสสั่งซื้อ</th>
                      <th>วันที่จัดส่ง</th>
                      <!-- <th>จำนวนเงินทั้งหมด</th> -->
                      <th>จำนวนเงินที่คงเหลือที่ยังไม่จ่าย</th>
                      <th>จำนวนเงินที่จ่าย</th>
                      <th>จำนวนเงินที่คงเหลือ</th>
                      <th>บันทึกข้อความ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in merges" :key="`list_payment_order_${index}`">
                      <td class="text-center">{{ data.order_code }}</td>
                      <td class="text-center">{{ data.expected_delivery_date }}</td>
                      <td class="text-right">
                        {{ data.amount  }}
                      </td>
                      <td class="text-right">
                        <base-input
                          :name="`paid_${index}`"
                          v-decimal-format
                          placeholder="จำนวนเงินที่จ่าย"
                          :rules="{ required: true }"
                          v-model="data.paid"
                          @change="changePaid(data)"
                        >
                        </base-input>
                      </td>
                      
                      <td class="text-right"> {{ data.remain  }} </td>
                      <td class="text-center">
                        <b-button variant="dark" @click="onEditOrderRemarkHandler(data.order_id)"><i class="fa fa-comment"></i> Remark </b-button>
                      </td>
                    </tr>
                    <tr v-if="merges.length === 0" class="text-center">
                      <td colspan="4">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-8"></div>
            <div class="col-4">
              <div class="row">
                <div class="col-6 text-right">รวม :</div>
                <div class="col-6"> {{ total  }} </div>
              </div>
            </div>
            <div class="col-12">
              <hr class="my-4" />
            </div>
            <div class="col-12">
              <h6 class="heading-small text-muted mb-4">อัพโหลดภาพ</h6>
              <div class="row">
                <div class="col-12">
                  <div class="mb-4">
                    <label class="form-label">หลักฐาน</label>
                    <div class="card" style="border: 1px solid #cad1d7; border-radius: 5px">
                      <div class="card-body row" :class="displayImgs.length < 2 &&'m-auto'" style="height: 230px; overflow: auto">
                        <input type="file" id="fileUpload" accept="image/*" style="display: none" @change="uploadFileChangeHandler" multiple name="imgs[]"/>
                        <!-- <span
                          class="thumbnail"
                          v-for="(img, index) in displayImgs"
                          :key="`display_image_${index}`"
                        >
                          <div class="action-delete">
                            <a
                              href="javascript:void(0)"
                              @click="deleteImageHandler(img, index)"
                            >
                              <i class="fa fa-times"></i>
                            </a>
                          </div>
                          <img
                            :src="img ? `${img.url || img}` : noImageData"
                            class="img-thumbnail"
                            style="max-width: 180px"
                            @click="uploadFileHandler()"
                          />
                        </span> -->
                        <div class="col-sm-6 col-md-4 col-lg-3 mb-4" v-for="(img, index) in displayImgs" :key="`display_image_${index}`">
                          <span class="thumbnail">
                            <div class="action-delete">
                              <a href="javascript:void(0)" @click="deleteImageHandler(img, index)">
                                <i class="fa fa-times"></i>
                              </a>
                            </div>
                            <img :src="img ? `${img.url || img}` : noImageData" class="img-thumbnail" style="max-width: 180px" @click="uploadFileHandler()"/>
                          </span>
                        </div>
                        <!-- กรณีไม่มีรูป -->
                        <img v-if="!displayImgs.length" :src="noImageData" class style="max-width: 180px" @click="uploadFileHandler()" />
                      </div>
                    </div>
                    <div class="invalid-feedback" style="display: block" v-if="!displayImgs.length">
                      The priority field is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-4">
                <label class="form-label optional">หมายเหตุ</label>
                <b-form-textarea
                  name="address"
                  placeholder="หมายเหตุสำหรับการจ่ายเงิน"
                  v-model="item.remark"
                  rows="3"
                  max-rows="5"
                ></b-form-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4" />
    </div>
    <dialog-add-order ref="dialogAddOrder" @selected-order="getValueOrders" />
    <dialog-order-remark ref="dialogOrderRemark" />
  </div>
</template>
<script>
import $ from "jquery";
import Select2Customer from "../component/select2.customer";
import dialogAddOrder from "./dialog.add.order";
import dialogOrderRemark from "./dialog.order.remark";
import Constants from "../../../util/constants";
import Config from "../../../util/config";
export default {
  name: "product-form-product",
  components: {
    Select2Customer,
    dialogAddOrder,
    dialogOrderRemark
  },
  data() {
    return {
      noImageData: Constants.noImageData,
      status_payments: Constants.payment_status,
      type_payments: Constants.payment_types,
      displayImage: "",
      total: 0,
      customers: [],
      defaultEmpty: [],
      imgs: [],
      displayImg: "",
      displayImgs: []
    };
  },
  props: ["item", "action", "merges", "flagEdit", "images", "imageDeletes"],
  methods: {
    async getCustomerById(customer_id) {
      this.item.customer_id = customer_id;
    },
    uploadFileHandler() {
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler(event) {
      const vm = this;
      let img = event.target.files;
      if (img.length > 0) {
        for (let i = 0; i < img.length; i++) {
          var reader = new FileReader();
          reader.onload = event => {
            vm.displayImgs.push({ url: event.target.result });
          };
          reader.readAsDataURL(img[i]);
          // vm.item.imageDisplays.push(img[i]);
          vm.item.upload.push(img[i]);
        }
        vm.imgs = img;
      } else {
        this.displayImg = null;
        this.file = null;
        this.imgs = [];
        // this.displayImgs = [];
      }
    },
    changeCustomerHandler() {
      //this.item.customer_id = customer_id;
      this.merges.splice(0, this.merges.length);
    },
    async getCustomers() {
      
    },
    onAddOrderHandler() {
      if (!this.item.customer_id) {
        this.AlertUtils.alert("warning", "กรุณาเลือกข้อมูลลูกค้า");
        return;
      }
      this.$refs.dialogAddOrder.getListItems(this.item.customer_id);
      this.$bvModal.show("dialog_add_order");
    },
    onEditOrderRemarkHandler(order_id) {
      this.$refs.dialogOrderRemark.load_order(order_id);
      this.$bvModal.show("dialog_payment_order_remark");
    },
    getValueOrders(dataRows) {
      for (const v of dataRows) {
        let flagInsert = true;
        for (const vv of this.merges) {
          if (vv.order_id == v.id) {
            flagInsert = false;
            break;
          }
        }
        if (flagInsert) {
          v.paid = "";
          v.remain = "";
          v.order_id = v.id;
          this.merges.push(JSON.parse(JSON.stringify(v)));
        }
      }
      this.onTotalHandler();
    },
    changePaid(value) {
      if (value.paid) {
        const paid = parseFloat("" + value.paid);
        if (paid > value.amount) {
          value.paid = "";
          value.remain = 0;
        } else {
          value.remain = value.amount - paid;
        }
      }
      this.onTotalHandler();
    },
    changeTypePayment() {
      this.item.status = 2;
      // ยกเลิกระบบเปลี่ยนแปลง อัตโนมัติ
      /*
      if (this.item.type == 2) {
        this.item.status = 1;
      } else {
        this.item.status = 2;
      }
      */
    },
    onTotalHandler() {
      if (this.merges.length > 0) {
        let total = 0;
        let paidTotal = 0;
        this.merges.forEach(v => {
          total += v.amount;
          if (v.paid) {
            paidTotal += parseFloat("" + v.paid);
          }
        });
        this.total = paidTotal;
        this.item.amount = total;
      }
    },
    deleteImageHandler(img, index) {
      const image = this.item.upload[index];

      if (image && image.id != 0) {
        this.imageDeletes.push(image.id);
        if (image.id_t) {
          this.imageDeletes.push(image.id_t);
        }
        this.item.upload.splice(index, 1);
      } else {
        let cnt = this.item.upload.filter(v => v.id != 0).length;
        this.item.upload.splice(index - cnt, 1);
      }
      this.displayImgs.splice(index, 1);
    },
    async init() {
      if (this.action == "edit") {
          this.$refs.Select2Customer.initialValue(this.item.customer_id,this.item.customer_name);
          this.imgs = JSON.parse(this.item.upload);
          this.displayImgs = JSON.parse(this.item.upload) || [];
      }
    
    }
  },
  async mounted() {
   
  },
  watch: {
    "item.upload_encode"(newVal) {
      // console.log("upload_encode: ", newVal);
      if (newVal) {
        this.displayImage = "" + newVal;
      } else {
        this.displayImage = "";
      }
    },
    "item.imageDisplays"(newVal, oldVal) {
      // console.log("imgs: ", this.item, newVal);
      if (newVal) {
        this.imgs = newVal;
        this.displayImgs = newVal;
      }
    }
  }
};
</script>

<style scoped>
.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.img-thumbnail {
  min-width: 180px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.action-delete{
  position:absolute;
  left: 25px;
  top: 5px;
}
</style>
